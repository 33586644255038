import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 960.000000 960.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,960.000000) scale(0.100000,-0.100000)">
<path d="M4470 9473 c-147 -11 -410 -46 -545 -74 -730 -149 -1403 -476 -1970
-957 -130 -111 -377 -359 -490 -493 -447 -528 -745 -1115 -913 -1799 -45 -183
-61 -266 -94 -510 -20 -149 -22 -207 -22 -595 -1 -445 2 -493 50 -800 85 -539
288 -1087 586 -1577 63 -104 196 -300 225 -334 10 -10 40 -49 68 -84 100 -131
223 -269 370 -413 705 -696 1644 -1132 2640 -1227 311 -30 853 -22 1140 16
784 103 1493 395 2107 867 938 722 1532 1776 1675 2972 26 218 26 922 0 1145
-41 345 -132 729 -246 1035 -104 278 -259 589 -419 842 -44 70 -160 236 -185
265 -12 14 -62 77 -112 140 -195 247 -621 641 -883 817 -504 339 -1003 555
-1558 675 -342 75 -476 87 -954 91 -228 1 -440 1 -470 -2z m705 -264 c112 -14
176 -40 220 -92 78 -91 64 -253 -29 -334 -54 -48 -94 -57 -220 -49 -61 4 -128
9 -148 13 -37 5 -38 6 -38 49 0 40 2 44 24 44 23 0 23 3 35 137 7 76 15 141
17 145 2 4 -5 9 -16 12 -11 3 -20 8 -20 11 0 3 0 21 0 40 0 35 1 35 43 35 23
0 83 -5 132 -11z m-525 -14 c39 -20 50 -43 50 -104 0 -39 -5 -55 -20 -68 -23
-20 -26 -33 -7 -33 22 0 65 -48 72 -80 17 -76 -21 -139 -95 -160 -40 -12 -321
-39 -332 -33 -5 2 -8 22 -8 44 0 30 4 39 16 39 15 0 16 11 10 107 -11 165 -16
193 -36 193 -14 0 -19 10 -22 43 -3 23 -4 43 -3 45 4 5 183 19 263 21 57 1 92
-4 112 -14z m-466 -33 c2 -4 7 -24 11 -45 6 -30 4 -37 -9 -37 -20 0 -20 -1 5
-107 52 -220 0 -321 -172 -331 -125 -8 -189 55 -223 221 -24 115 -25 117 -46
117 -22 0 -25 4 -34 50 l-6 35 101 24 c55 13 105 19 109 15 5 -5 12 -26 15
-46 6 -32 4 -38 -10 -38 -19 0 -18 -11 17 -144 29 -109 62 -138 116 -100 32
22 30 81 -9 227 -7 25 -15 37 -28 37 -13 0 -20 12 -28 45 l-10 44 81 20 c93
22 113 24 120 13z m1581 -52 c44 -12 96 -25 116 -30 l36 -10 7 -123 c14 -271
16 -287 38 -287 18 0 20 -4 14 -42 -4 -23 -9 -44 -12 -47 -5 -5 -238 49 -256
60 -9 6 -9 17 0 48 12 38 14 40 43 34 28 -5 30 -4 27 18 -2 20 -12 26 -62 37
-33 7 -65 11 -73 8 -21 -9 -15 -36 8 -36 19 0 21 -4 14 -37 -3 -21 -10 -42
-15 -47 -9 -9 -188 30 -203 44 -5 4 -4 23 0 41 7 26 15 35 35 37 22 3 40 25
103 129 54 89 73 128 66 137 -13 15 7 86 23 86 6 0 47 -9 91 -20z m-2170 -124
c39 -17 60 -46 69 -96 4 -24 1 -42 -9 -55 -21 -29 -19 -35 14 -35 56 0 115
-99 96 -162 -16 -53 -60 -79 -251 -153 -64 -25 -118 -45 -120 -45 -6 0 -34 66
-34 80 0 5 7 10 15 10 18 0 19 8 1 51 -7 19 -30 78 -51 132 -32 85 -40 98 -57
94 -15 -4 -23 2 -34 28 -8 19 -14 38 -14 43 0 5 28 19 63 31 34 13 76 28 92
36 40 17 115 43 140 48 36 8 45 7 80 -7z m2810 -88 c94 -39 172 -72 174 -74 7
-7 -52 -156 -63 -160 -15 -6 -86 23 -86 35 0 4 5 23 11 41 12 33 3 49 -27 50
-10 0 -114 -234 -114 -257 0 -6 9 -15 20 -18 11 -3 20 -10 20 -14 0 -13 -30
-81 -35 -81 -2 0 -30 12 -62 26 -32 14 -82 35 -110 46 -57 21 -59 25 -41 75
13 35 19 38 47 23 17 -8 25 6 76 125 32 75 56 137 54 139 -2 2 -14 7 -26 10
-19 6 -25 1 -37 -28 -8 -20 -20 -36 -26 -36 -18 0 -80 29 -80 37 0 4 14 43 31
85 l31 77 37 -15 c20 -8 113 -47 206 -86z m-3380 -176 c22 -20 87 -81 144
-135 90 -85 108 -97 130 -92 22 5 29 0 45 -30 11 -19 17 -37 15 -39 -2 -3 -56
-34 -119 -69 l-115 -64 -22 36 -22 36 25 21 c22 18 23 22 9 34 -13 11 -22 9
-59 -11 -66 -35 -77 -45 -70 -63 7 -18 25 -21 40 -6 7 7 18 -3 33 -31 13 -22
21 -41 20 -43 -3 -2 -132 -75 -181 -102 -3 -2 -16 13 -28 33 l-22 37 21 15
c20 16 20 16 -9 141 -16 69 -30 135 -30 148 0 15 -6 22 -18 22 -18 0 -62 64
-52 73 9 8 215 127 220 127 3 0 23 -17 45 -38z m3849 -64 c56 -34 105 -64 109
-68 4 -4 -13 -88 -38 -188 -25 -100 -45 -189 -45 -198 0 -9 7 -22 16 -28 14
-11 14 -16 -6 -49 -21 -35 -23 -36 -49 -23 -35 19 -194 116 -203 125 -4 4 4
23 17 44 25 35 27 36 49 22 24 -16 36 -11 36 14 0 20 -105 82 -125 75 -20 -8
-19 -24 1 -39 15 -11 15 -14 0 -43 -9 -17 -20 -33 -25 -37 -15 -9 -192 105
-184 118 31 55 44 66 63 55 16 -10 24 -8 47 13 15 14 68 61 116 104 52 46 83
81 77 85 -8 5 -4 19 10 43 12 21 24 37 27 37 3 0 51 -28 107 -62z m-4265 -170
c20 -25 22 -33 12 -49 -10 -17 -1 -33 75 -129 74 -92 91 -109 109 -104 16 4
28 -2 44 -22 11 -15 21 -32 21 -38 0 -6 -30 -33 -67 -61 -36 -27 -73 -56 -80
-64 -13 -12 -19 -10 -46 18 -28 27 -30 34 -19 51 11 18 9 25 -14 55 -14 19
-29 35 -33 35 -3 0 -26 -17 -51 -39 l-44 -38 27 -37 c29 -38 41 -43 53 -25 7
12 64 -38 64 -57 0 -6 -17 -24 -37 -40 -21 -15 -40 -31 -43 -34 -13 -15 -78
-60 -88 -60 -6 0 -20 14 -32 30 -17 24 -19 34 -10 45 17 20 -154 238 -181 230
-13 -4 -27 3 -44 22 -13 15 -25 31 -25 35 0 8 160 138 170 138 9 0 50 -57 50
-70 0 -5 -4 -10 -10 -10 -18 0 -10 -24 19 -59 l28 -35 47 38 c25 21 46 41 46
44 0 3 -11 20 -26 39 -20 26 -30 32 -48 27 -18 -4 -28 1 -46 26 -17 24 -20 34
-11 43 18 18 155 125 161 126 3 0 16 -14 29 -31z m2641 -113 c350 -55 596
-123 874 -242 599 -256 1073 -633 1462 -1163 483 -657 729 -1545 629 -2275
-33 -240 -49 -316 -115 -547 -127 -440 -381 -907 -691 -1270 -55 -66 -252
-264 -329 -334 -397 -356 -920 -633 -1440 -764 -514 -129 -914 -137 -1425 -26
-457 100 -934 318 -1313 603 -653 491 -1104 1173 -1296 1961 -66 270 -103 600
-92 811 42 758 324 1468 815 2051 66 79 333 346 415 415 188 159 476 347 693
450 151 72 171 81 280 124 278 110 580 183 933 225 86 10 499 -3 600 -19z
m2227 -152 c46 -29 83 -87 83 -127 0 -26 13 -35 25 -16 3 6 10 10 15 10 14 0
80 -61 80 -74 0 -6 -25 -38 -55 -71 l-54 -60 -45 44 c-36 35 -43 48 -36 62 17
31 11 60 -19 90 -61 60 -82 2 -32 -86 16 -29 37 -77 46 -108 14 -49 14 -60 2
-90 -17 -40 -56 -73 -97 -82 -78 -17 -179 52 -210 144 -9 29 -21 48 -24 42
-12 -19 -33 -12 -75 27 l-40 37 59 65 59 65 45 -44 c39 -38 44 -48 39 -76 -9
-55 51 -120 85 -93 10 8 4 27 -27 84 -72 133 -73 194 -6 253 48 42 121 44 182
4z m-5322 -268 c210 -101 204 -99 232 -84 21 12 27 10 53 -17 l29 -29 -87 -97
c-48 -53 -92 -97 -98 -97 -5 -1 -23 12 -39 29 -27 29 -28 30 -11 49 19 21 14
41 -8 41 -18 0 -96 -85 -96 -104 0 -23 20 -29 39 -12 16 15 19 14 44 -12 15
-16 27 -32 27 -38 -1 -5 -32 -43 -70 -84 l-70 -75 -31 31 c-27 28 -29 34 -18
52 11 18 8 28 -22 79 -20 32 -53 88 -74 126 -22 37 -45 67 -53 67 -17 0 -72
38 -72 50 0 13 172 200 179 196 3 -2 69 -34 146 -71z m5684 -107 l64 -81 -32
-29 c-25 -23 -36 -27 -46 -18 -16 14 -21 11 -118 -70 -79 -66 -96 -97 -72
-133 33 -50 61 -42 182 50 59 45 72 60 67 76 -4 14 2 26 22 43 16 13 32 23 36
24 3 0 34 -34 67 -77 l61 -76 -32 -27 c-25 -22 -36 -25 -49 -17 -14 9 -30 0
-86 -44 -133 -108 -185 -130 -260 -110 -78 21 -152 123 -153 209 0 76 37 126
177 238 42 33 49 43 41 58 -5 10 -8 19 -6 20 1 1 16 12 32 24 16 12 31 22 35
21 3 0 35 -37 70 -81z m-6115 -199 c29 -17 34 -24 26 -39 -8 -15 1 -24 58 -60
37 -24 90 -58 119 -76 53 -35 73 -41 73 -24 0 17 24 11 54 -14 l30 -23 -55
-86 c-30 -48 -58 -87 -63 -87 -5 0 -24 9 -43 21 -27 17 -31 24 -22 35 7 8 10
16 8 18 -16 13 -191 126 -195 126 -3 0 46 -56 110 -125 127 -139 121 -119 67
-208 -28 -45 -19 -45 -208 13 -68 21 -123 36 -123 35 0 -2 39 -29 86 -60 71
-46 89 -54 101 -44 12 10 21 9 49 -6 19 -10 33 -24 30 -31 -8 -22 -89 -144
-95 -144 -3 0 -21 9 -39 20 -24 15 -32 25 -27 39 4 15 -14 31 -92 81 -54 35
-108 70 -121 78 -17 11 -25 12 -37 2 -12 -10 -21 -8 -50 10 l-35 22 22 36 c29
48 119 182 122 182 2 0 52 -16 112 -35 60 -19 113 -35 117 -35 5 0 -28 39 -72
87 -45 48 -81 93 -81 100 0 12 127 213 135 213 2 0 20 -9 39 -21z m6485 -316
c72 -121 81 -143 81 -186 0 -42 -5 -53 -30 -77 -36 -34 -76 -45 -113 -31 -25
10 -27 9 -27 -17 -1 -66 -63 -122 -136 -122 -34 0 -51 7 -76 29 -39 34 -192
286 -185 304 2 7 17 19 31 27 22 11 31 12 46 2 16 -10 36 -1 139 60 102 61
120 75 115 92 -3 15 3 26 23 38 15 10 33 18 39 18 7 0 48 -62 93 -137z m107
-3844 c19 -11 38 -35 45 -53 12 -32 13 -33 38 -20 48 25 105 13 154 -34 23
-22 27 -34 27 -78 0 -45 -10 -71 -71 -191 -39 -76 -75 -142 -80 -147 -5 -6
-23 -3 -45 7 -29 14 -35 22 -31 40 5 20 -8 29 -124 89 -101 52 -131 64 -136
53 -7 -20 -22 -19 -60 4 l-32 20 46 88 c68 132 111 200 140 223 33 26 86 26
129 -1z m-6527 -390 c82 -115 86 -88 -27 -223 -35 -42 -61 -76 -58 -76 3 0 40
14 83 31 43 17 95 37 115 45 l37 13 71 -100 c38 -55 70 -105 70 -112 0 -7 -14
-21 -30 -31 -24 -14 -34 -15 -45 -6 -11 9 -23 5 -59 -22 -25 -18 -77 -55 -116
-83 -58 -41 -70 -54 -65 -72 4 -17 -3 -26 -31 -44 -30 -18 -38 -20 -44 -8 -5
8 -31 45 -59 84 -28 38 -51 73 -51 77 0 9 55 48 68 48 6 0 12 -6 15 -12 3 -9
14 -6 38 12 19 13 66 47 104 74 39 27 61 45 50 41 -11 -4 -76 -28 -145 -55
-183 -70 -162 -69 -209 -4 l-41 57 22 31 c12 17 25 33 28 36 3 3 21 25 40 50
19 25 37 47 40 50 16 15 72 93 60 84 -8 -6 -50 -37 -93 -68 -61 -45 -76 -60
-67 -71 8 -10 3 -19 -25 -41 -19 -15 -37 -25 -39 -23 -30 35 -96 134 -96 144
0 8 14 23 30 35 24 17 34 19 45 10 20 -17 248 145 240 170 -6 18 37 59 61 60
6 0 43 -45 83 -101z m6228 5 c27 -20 32 -29 23 -39 -9 -11 7 -28 82 -83 51
-38 100 -78 109 -88 52 -60 64 -145 28 -204 -66 -110 -154 -151 -250 -116 -35
12 -143 82 -183 119 -23 20 -37 22 -53 6 -8 -8 -19 -5 -42 12 -17 12 -31 26
-31 31 0 12 114 167 123 168 4 0 21 -10 37 -22 21 -15 28 -28 25 -42 -4 -17
10 -31 70 -73 122 -85 167 -92 189 -30 13 36 -8 65 -105 137 -64 48 -84 59
-95 49 -10 -8 -22 -6 -49 11 -20 12 -35 25 -33 29 7 15 113 161 117 161 2 0
19 -12 38 -26z m-304 -482 l57 -58 -43 -42 c-39 -38 -46 -42 -70 -33 -35 14
-80 -3 -97 -35 -12 -21 -11 -27 3 -41 15 -15 23 -14 109 28 75 35 103 44 146
43 45 0 57 -4 83 -30 82 -82 16 -250 -111 -284 -34 -9 -39 -14 -35 -33 4 -16
-5 -33 -30 -60 -42 -44 -47 -42 -117 30 l-47 48 40 43 c34 38 43 43 69 37 36
-8 81 14 100 49 10 21 11 29 2 38 -10 10 -32 2 -99 -32 -132 -69 -209 -60
-256 31 -47 88 28 218 136 236 31 5 34 8 24 23 -9 15 -5 25 24 58 19 22 39 41
44 41 6 1 36 -25 68 -57z m-5413 -153 l95 -91 -91 -179 c-84 -166 -90 -181
-76 -200 13 -19 12 -24 -17 -52 l-31 -32 -97 98 -98 97 32 32 c32 31 32 32 53
13 18 -16 23 -17 30 -5 14 22 14 23 -35 69 -34 32 -50 41 -66 36 -20 -7 -20
-8 -3 -26 17 -19 16 -21 -11 -49 -16 -17 -32 -30 -37 -30 -12 0 -148 131 -148
143 0 6 12 23 27 39 25 26 28 27 44 12 16 -15 27 -11 145 55 70 39 131 71 135
71 5 0 6 7 3 15 -5 13 32 73 46 74 3 0 48 -40 100 -90z m317 -282 c23 -17 60
-45 82 -60 l41 -28 -23 -35 c-18 -28 -27 -33 -47 -29 -20 4 -29 -1 -47 -30
-13 -19 -23 -36 -23 -38 0 -7 88 -67 98 -67 6 0 20 16 32 35 18 28 20 39 10
50 -10 11 -8 22 10 50 12 19 24 35 27 35 3 0 41 -26 86 -57 44 -32 85 -61 90
-64 5 -4 -2 -23 -18 -43 -22 -28 -31 -34 -45 -26 -15 8 -30 -8 -96 -102 -92
-131 -91 -128 -73 -139 12 -7 11 -13 -3 -36 -30 -51 -38 -50 -132 17 l-87 62
25 31 c15 20 32 31 45 30 13 -2 29 9 46 31 14 18 25 35 25 39 0 8 -82 67 -94
67 -6 0 -21 -15 -34 -34 -19 -29 -21 -37 -11 -54 10 -16 8 -24 -11 -50 -13
-18 -26 -32 -29 -32 -16 2 -166 118 -168 131 -2 8 8 27 22 43 18 23 27 27 37
18 21 -17 186 214 170 238 -8 13 -5 24 10 48 11 18 25 32 31 32 7 0 31 -15 54
-33z m4560 -197 c128 -101 157 -120 183 -118 24 3 34 -3 50 -28 l20 -31 -42
-29 c-24 -16 -74 -51 -112 -78 l-70 -49 -23 29 c-28 36 -29 50 -3 66 25 16 25
25 0 33 -30 9 -120 -53 -120 -83 0 -18 4 -22 15 -18 8 3 21 8 28 11 11 5 65
-61 56 -69 -2 -1 -42 -29 -88 -61 l-85 -58 -23 29 c-27 34 -29 50 -8 62 14 7
13 16 -5 73 -12 35 -32 101 -46 147 -18 57 -30 82 -39 79 -12 -5 -55 43 -55
62 0 9 198 151 210 151 3 0 73 -54 157 -120z m-463 -186 c20 -42 36 -78 36
-80 0 -5 -50 -30 -75 -38 -17 -5 -23 0 -34 29 -7 19 -16 35 -21 35 -16 0 -40
-12 -40 -20 0 -19 120 -260 129 -260 5 0 13 5 16 10 12 20 32 9 48 -26 10 -19
15 -39 12 -44 -8 -14 -216 -110 -225 -105 -14 8 -34 79 -24 82 36 13 35 20
-26 146 -34 70 -67 127 -73 127 -7 0 -19 -4 -27 -10 -12 -8 -12 -14 3 -46 10
-20 17 -37 15 -38 -2 -1 -22 -12 -45 -24 l-42 -23 -36 72 c-20 40 -35 78 -33
86 3 12 369 200 394 202 7 1 28 -33 48 -75z m-3494 4 c96 -46 109 -56 110 -78
0 -14 -14 -104 -30 -200 l-30 -175 21 -16 c20 -15 20 -16 3 -52 -10 -20 -21
-37 -26 -37 -6 0 -225 107 -237 117 -3 2 3 19 13 39 16 34 19 36 51 30 30 -6
35 -4 35 13 0 14 -15 28 -51 45 -28 15 -59 26 -69 26 -29 0 -34 -19 -10 -38
22 -18 20 -28 -13 -79 -5 -8 -34 2 -100 34 -50 25 -94 47 -96 49 -4 4 16 49
32 71 7 9 14 10 29 2 15 -8 22 -8 27 0 4 6 52 56 106 112 69 70 95 103 86 108
-9 6 -8 16 5 44 10 21 22 37 27 37 4 0 57 -24 117 -52z m467 -201 c138 -46
199 -78 210 -112 21 -67 -17 -154 -69 -155 -15 0 -14 -5 7 -36 64 -94 -10
-213 -125 -200 -41 5 -305 88 -317 100 -3 2 0 20 7 40 7 23 18 36 32 38 17 2
28 26 65 138 40 122 42 136 27 147 -14 10 -14 17 -4 47 6 20 17 36 24 36 6 0
71 -20 143 -43z m2440 -90 c141 -209 159 -232 186 -230 20 1 28 -5 36 -29 6
-17 11 -36 11 -43 0 -16 -247 -92 -259 -79 -5 5 -13 26 -16 47 -6 32 -5 37 12
37 25 0 33 8 26 26 -7 18 -22 18 -89 -2 -41 -12 -54 -20 -54 -35 0 -14 7 -19
25 -19 27 0 35 -10 38 -54 2 -27 -2 -30 -83 -57 -103 -35 -116 -36 -124 -5 -3
13 -9 31 -12 39 -3 10 2 18 15 22 20 6 21 13 21 161 0 124 -3 154 -14 154 -11
0 -42 80 -33 86 6 4 234 73 242 73 6 0 38 -41 72 -92z m-2012 -28 c44 -7 83
-15 88 -19 4 -4 4 -24 -1 -46 -6 -27 -13 -38 -24 -36 -18 3 -22 -10 -39 -128
-7 -47 -9 -98 -6 -112 9 -33 40 -52 74 -43 31 7 50 52 69 164 14 79 14 86 -1
97 -13 10 -16 21 -10 54 6 36 10 41 29 37 11 -3 56 -11 99 -18 81 -12 82 -13
71 -68 -5 -21 -12 -31 -25 -31 -11 0 -19 -1 -19 -3 0 -2 -9 -52 -20 -112 -32
-175 -86 -245 -188 -245 -121 0 -201 54 -221 150 -10 46 -9 73 7 169 18 109
18 114 1 118 -14 4 -17 12 -13 37 9 51 13 58 31 52 10 -2 54 -10 98 -17z
m1465 -63 c88 -45 134 -157 110 -272 -10 -48 -20 -66 -54 -99 -23 -23 -62 -48
-86 -57 -54 -19 -266 -52 -280 -43 -17 10 -12 85 5 85 19 0 19 14 0 142 -22
147 -22 146 -48 150 -18 2 -23 10 -25 46 -2 23 -2 42 0 43 2 0 55 9 118 19
133 21 197 18 260 -14z m-706 -31 c50 -24 66 -51 66 -108 0 -41 -5 -53 -29
-76 l-29 -28 28 -17 c61 -35 63 -144 4 -196 l-35 -30 -184 0 -185 0 0 50 c0
38 3 48 14 44 8 -3 16 -2 19 3 2 4 8 72 12 151 7 140 7 142 -14 142 -18 0 -21
6 -21 46 l0 47 159 -6 c121 -4 168 -9 195 -22z"/>
<path d="M5176 9108 c-8 -27 -28 -280 -22 -286 3 -3 23 -1 43 4 87 21 125 187
60 258 -26 28 -76 43 -81 24z"/>
<path d="M4467 9113 c-2 -5 -2 -27 1 -50 4 -41 6 -43 37 -43 21 0 35 6 39 16
10 25 7 59 -6 72 -13 13 -64 16 -71 5z"/>
<path d="M4480 8903 c0 -66 9 -83 41 -83 57 0 90 68 51 103 -10 10 -35 17 -55
17 -36 0 -37 -1 -37 -37z"/>
<path d="M5732 8925 c-18 -30 -31 -59 -28 -64 3 -5 19 -11 36 -13 l30 -5 0 69
c0 37 -1 68 -3 68 -1 0 -17 -25 -35 -55z"/>
<path d="M3443 8873 c-17 -6 -16 -26 2 -69 l14 -35 31 16 c22 12 30 22 30 43
0 44 -33 63 -77 45z"/>
<path d="M3515 8708 c-11 -6 -22 -12 -24 -14 -7 -6 33 -104 43 -104 6 0 26 9
44 20 72 44 11 140 -63 98z"/>
<path d="M2946 8524 c7 -31 15 -58 17 -61 7 -6 67 26 67 36 0 10 -77 81 -88
81 -5 0 -4 -25 4 -56z"/>
<path d="M6760 8495 c-24 -24 -42 -46 -39 -48 14 -10 59 -37 62 -37 3 0 10 21
16 48 7 26 14 55 17 65 9 28 -9 20 -56 -28z"/>
<path d="M2012 6670 c-22 -21 -22 -25 -22 -331 l0 -310 25 -24 c21 -22 33 -25
90 -25 l65 0 0 -635 0 -635 -67 0 c-74 -1 -96 -11 -113 -54 -14 -38 -13 -563
1 -605 6 -17 19 -36 29 -41 12 -6 230 -10 573 -10 l554 0 21 23 c16 17 22 37
23 73 l1 49 17 -52 c32 -101 -7 -93 488 -93 275 0 441 4 453 10 12 6 28 39 42
83 l23 72 5 -62 c3 -39 12 -70 22 -83 18 -20 27 -20 1460 -20 1446 0 1532 2
1698 36 460 95 681 394 648 875 -12 181 -93 362 -216 482 l-49 49 37 56 c80
120 111 243 113 447 2 194 -33 310 -131 440 -80 106 -249 210 -412 254 -188
51 -164 51 -1806 51 -1441 0 -1533 -1 -1553 -18 -17 -13 -37 -76 -92 -292 -38
-151 -88 -347 -111 -435 l-42 -160 -13 45 c-8 25 -30 106 -49 180 -114 431
-175 645 -190 661 -16 18 -48 19 -759 19 -732 0 -741 0 -763 -20z m3706 -695
c4 -4 2 -1258 -2 -1263 -7 -6 -305 -4 -316 3 -7 4 -10 207 -10 635 l0 629 68
3 c64 3 254 -2 260 -7z m1154 -35 c17 -21 19 -37 16 -112 -2 -50 -8 -92 -15
-99 -7 -7 -46 -14 -87 -17 l-76 -5 0 133 0 132 71 -4 c61 -3 74 -7 91 -28z
m58 -903 c49 -25 70 -72 70 -157 0 -72 -2 -79 -32 -114 -37 -43 -79 -55 -190
-56 l-68 0 0 170 0 170 98 0 c57 0 107 -5 122 -13z m-2520 -243 c0 -83 -1 -85
-22 -82 -13 2 -22 9 -21 18 2 25 36 150 39 150 2 0 4 -39 4 -86z m-1392 -16
l20 -68 -24 0 c-23 0 -24 2 -23 78 0 42 2 73 4 67 2 -5 13 -40 23 -77z"/>
<path d="M2030 3928 c-25 -13 -52 -35 -60 -50 -13 -25 -13 -31 0 -55 8 -15 31
-36 50 -47 l35 -21 2772 0 2771 0 39 31 c65 53 59 99 -19 138 l-52 26 -2746 0
-2746 0 -44 -22z"/>
<path d="M3322 3693 c-18 -2 -94 -41 -170 -87 -75 -45 -202 -121 -282 -168
-80 -47 -228 -135 -330 -195 -102 -60 -204 -120 -227 -133 -73 -40 -76 -39
182 -75 163 -24 489 -71 1660 -241 209 -30 463 -67 565 -81 l185 -27 365 53
c201 29 534 77 740 107 708 103 926 135 1205 174 305 43 325 47 325 58 0 4
-96 63 -213 131 -116 69 -288 170 -382 226 -364 217 -417 247 -448 256 -31 9
-3104 10 -3175 2z m1247 -140 c16 -14 21 -31 23 -79 2 -65 -8 -88 -43 -99 -29
-10 -23 -23 13 -27 31 -3 33 -6 60 -93 39 -122 41 -144 18 -138 -10 3 -24 19
-30 36 -6 18 -18 46 -25 62 -11 27 -14 28 -21 11 -4 -10 -3 -26 3 -35 21 -33
24 -82 12 -157 -6 -41 -17 -81 -24 -88 -13 -13 -13 -76 0 -113 6 -18 4 -23 -9
-23 -13 0 -16 13 -16 75 0 52 4 74 12 73 7 -2 17 27 26 76 11 62 12 87 3 117
-11 35 -15 39 -46 39 -31 0 -35 -3 -45 -39 -12 -47 10 -198 29 -193 7 1 11
-22 11 -73 0 -62 -3 -75 -16 -75 -12 0 -15 5 -9 23 16 48 17 96 3 110 -8 8
-20 52 -27 97 -11 73 -10 87 5 131 13 36 15 52 7 60 -8 8 -17 -5 -33 -49 -14
-37 -29 -62 -40 -65 -24 -6 -21 14 19 136 31 93 34 97 62 97 37 0 37 10 0 32
-32 17 -40 51 -33 129 6 55 67 78 111 42z m466 -8 c14 -13 25 -35 25 -49 0
-40 -30 -94 -57 -100 -12 -4 -23 -11 -23 -17 0 -6 7 -9 15 -5 23 8 62 -13 69
-38 11 -39 5 -51 -7 -16 -13 37 -39 49 -62 30 -18 -15 -21 -150 -4 -167 9 -9
7 -11 -6 -9 -16 2 -18 12 -16 80 1 48 -2 83 -10 92 -13 16 -45 19 -54 4 -10
-16 -55 -182 -55 -202 0 -39 20 -14 40 50 30 89 40 98 40 33 0 -32 4 -50 10
-46 6 3 10 2 10 -4 0 -5 -4 -11 -10 -13 -5 -1 -12 -76 -16 -165 l-7 -163 26 0
27 0 2 143 2 142 3 -142 4 -143 24 0 24 0 -6 220 c-5 164 -3 220 5 220 7 0 12
-8 13 -17 1 -32 36 -127 47 -131 7 -2 12 7 13 20 0 21 1 22 8 3 5 -11 4 -22 0
-23 -5 -2 -7 -7 -4 -12 3 -4 -4 -6 -15 -3 -14 4 -26 23 -40 67 l-19 61 6 -217
6 -218 -31 0 c-19 0 -32 5 -33 13 0 10 -2 10 -6 0 -2 -7 -19 -13 -35 -13 l-31
0 7 210 c3 116 5 210 3 210 -3 0 -12 -24 -21 -54 -12 -36 -24 -56 -37 -59 -10
-3 -17 -1 -14 3 3 5 0 10 -5 12 -7 2 -7 13 -1 33 6 17 20 67 32 113 18 70 26
85 47 93 16 6 29 6 38 -2 11 -9 14 -8 14 3 0 8 -11 20 -25 26 -31 14 -57 65
-53 105 2 25 11 36 38 49 48 24 76 22 105 -7z m59 -330 c4 -27 4 -34 -3 -20
-13 31 -24 93 -12 75 5 -8 12 -33 15 -55z"/>
<path d="M4488 3549 c-10 -5 -18 -17 -18 -25 0 -12 4 -13 21 -3 12 6 36 8 55
4 40 -7 45 7 9 23 -31 14 -45 15 -67 1z"/>
<path d="M4488 3509 c-33 -18 -19 -29 37 -28 60 2 69 10 30 27 -31 14 -45 15
-67 1z"/>
<path d="M4470 3442 c0 -33 27 -62 57 -62 27 0 53 31 53 63 0 26 -2 27 -55 27
-54 0 -55 -1 -55 -28z m40 -2 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z m50 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6
5 10 10 10 6 0 10 -4 10 -10z m-15 -40 c-3 -5 -12 -10 -20 -10 -8 0 -17 5 -20
10 -4 6 5 10 20 10 15 0 24 -4 20 -10z"/>
<path d="M4466 3327 c-5 -16 -55 -164 -63 -183 -3 -7 -1 -14 5 -14 5 0 22 34
38 75 16 41 32 75 35 75 4 0 9 -18 13 -40 5 -34 10 -40 30 -40 19 0 26 7 31
33 12 54 17 52 49 -27 18 -42 35 -74 38 -71 3 3 -10 49 -28 103 l-32 97 -57 2
c-37 1 -57 -2 -59 -10z"/>
<path d="M4940 3547 c-14 -6 -28 -16 -32 -22 -12 -16 -9 -35 5 -31 6 3 26 7
42 11 27 6 28 5 12 -7 -10 -8 -17 -18 -15 -23 2 -6 -1 -11 -7 -11 -5 -1 -9 2
-7 7 1 5 -5 9 -13 9 -21 0 -19 -23 6 -54 36 -46 83 -29 109 39 15 39 6 69 -25
83 -31 14 -42 14 -75 -1z m100 -57 c0 -5 -7 -10 -15 -10 -8 0 -14 -4 -12 -9 1
-5 -1 -9 -5 -10 -5 -1 -8 1 -8 4 0 3 0 12 0 20 0 8 9 15 20 15 11 0 20 -4 20
-10z m-45 -70 c-3 -5 -12 -10 -20 -10 -8 0 -17 5 -20 10 -4 6 5 10 20 10 15 0
24 -4 20 -10z"/>
<path d="M4928 2823 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4998 2823 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2027 7818 c39 -66 48 -78 58 -78 10 1 50 49 43 54 -6 6 -110 56
-115 56 -3 0 4 -15 14 -32z"/>
<path d="M8105 7254 c-11 -8 -26 -19 -34 -23 -18 -11 -6 -48 20 -62 16 -8 26
-7 45 6 26 16 31 44 14 76 -13 23 -19 23 -45 3z"/>
<path d="M7928 7148 c-44 -25 -45 -30 -17 -69 25 -35 60 -38 89 -7 24 26 24
31 5 68 -18 36 -28 36 -77 8z"/>
<path d="M8191 3334 c-12 -15 -21 -29 -21 -33 0 -3 18 -16 40 -27 37 -21 40
-21 54 -5 23 25 20 57 -6 75 -31 22 -43 20 -67 -10z"/>
<path d="M8374 3282 c-13 -9 -44 -54 -44 -64 0 -4 22 -19 50 -33 l49 -25 16
29 c20 40 19 57 -5 81 -20 20 -47 25 -66 12z"/>
<path d="M2155 2344 c-22 -13 -44 -25 -50 -28 -5 -2 3 -18 20 -35 l29 -32 28
53 c40 78 38 81 -27 42z"/>
<path d="M6994 1914 c4 -9 11 -36 17 -60 6 -24 14 -44 16 -44 10 0 63 35 63
41 0 8 -86 79 -95 79 -4 0 -4 -7 -1 -16z"/>
<path d="M3136 1668 c-10 -13 -29 -34 -42 -48 l-24 -26 35 -18 c19 -11 37 -17
40 -14 8 7 22 128 16 128 -4 0 -15 -10 -25 -22z"/>
<path d="M3670 1484 c-20 -51 -17 -66 14 -77 25 -8 32 -7 48 13 32 39 21 69
-29 84 -19 6 -25 2 -33 -20z"/>
<path d="M3606 1293 c-9 -26 -16 -50 -16 -54 0 -5 19 -14 41 -21 39 -11 43
-10 60 11 36 44 17 90 -43 105 -24 5 -27 2 -42 -41z"/>
<path d="M5978 1384 c4 -62 5 -66 25 -60 12 3 28 6 35 6 6 0 12 4 12 9 0 13
-61 111 -69 111 -3 0 -4 -30 -3 -66z"/>
<path d="M5393 1338 c3 -13 13 -78 23 -145 16 -106 21 -123 37 -123 93 0 119
176 39 263 -20 20 -35 27 -65 27 -35 0 -39 -2 -34 -22z"/>
<path d="M4700 1270 c0 -49 1 -50 30 -50 40 0 68 40 52 75 -9 20 -19 25 -47
25 l-35 0 0 -50z"/>
<path d="M4696 1124 c-3 -9 -6 -33 -6 -55 0 -39 0 -39 39 -39 23 0 46 7 55 16
20 19 21 61 2 80 -20 19 -82 18 -90 -2z"/>

</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
